// Variable definitions
var $                = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);

// Component Scripts
var medium       = require('./components/medium');


// Critical Load
// High priority/UI dependent scripts
$(document).on('ready', medium);

